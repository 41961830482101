var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "review",
      attrs: {
        title: "样本条码",
        "mask-closable": false,
        draggable: false,
        width: "900",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.barcodeList.length > 0
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                "max-height": "68vh",
                "overflow-y": "auto",
              },
            },
            _vm._l(_vm.barcodeList, function (item, index) {
              return _c("img", { key: index, attrs: { src: item } })
            }),
            0
          )
        : _c("no-data"),
      _vm.spinShow
        ? _c("Spin", { attrs: { size: "large", fix: "" } })
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "default" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }